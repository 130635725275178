import {Injectable} from '@angular/core';
import {computed, makeObservable, observable, runInAction} from 'mobx';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {setUser} from '@sentry/angular';
import {Observable, lastValueFrom} from 'rxjs';
import URL from 'url-parse';

import {AbstractAuthService} from '@shared/services/abstract-auth.service';
import {RequestError, getHttpErrorMessage} from '@shared/services/http-resource';
import {DEFAULT_THEME} from '@shared/services/abstract-lcap-branding.service';

import {
  AcceptInvitationParams,
  AuthInfo,
  AuthPageParams,
  PasswordResetParams,
  PortalSamlStatus,
  PortalSettings,
  SamlInvitationParams,
  User,
  UserInvitationInfo,
  UserSignInParams,
} from './auth.types';
import {HttpResource} from './http-resource';
import {EmbeddingService} from './embedding.service';

const RETURN_URL_STORAGE_KEY = 'lcap.returnUrl';

@Injectable({providedIn: 'root'})
export class AuthService implements AuthInfo, AbstractAuthService {
  @observable.deep settings: PortalSettings = {
    name: '',
    subdomain: '',
    branding: DEFAULT_THEME,
  };

  @observable.deep sso_saml?: PortalSamlStatus;
  @observable.deep user?: User;

  readonly signInPath = '/portal/sign-in';

  private initPromise?: Promise<void>;

  private resource = new HttpResource({
    url: '/portal/api/{{entity}}/{{entityId}}/{{subEntity}}/{{action}}.json',
  });

  constructor(
    private http: HttpClient,
    private embeddingService: EmbeddingService,
  ) {
    makeObservable(this);
  }

  get returnUrl(): string {
    return window.sessionStorage.getItem(RETURN_URL_STORAGE_KEY) ?? `${location.origin}/portal`;
  }

  set returnUrl(value: string) {
    window.sessionStorage.setItem(RETURN_URL_STORAGE_KEY, value);
  }

  get portalUrl(): string {
    const portalUrl = new URL(this.returnUrl, true);
    const query = {...portalUrl.query, ...this.embeddingService.embeddingQueryParams};

    return portalUrl.set('query', query).toString();
  }

  @computed
  get signInUrl(): string {
    return new URL(this.signInPath).set('query', this.embeddingService.embeddingQueryParams).toString();
  }

  @computed
  get authenticated(): boolean {
    return Boolean(this.user);
  }

  init() {
    this.initPromise ||= this.loadAuthInfo();

    return this.initPromise;
  }

  async getInvitationInfo({id, token}: AuthPageParams): Promise<UserInvitationInfo> {
    return this.resource.get({entity: 'invitations', entityId: id}, {query: {token}});
  }

  async acceptInvitationWithPassword({id, token}: AuthPageParams, params: AcceptInvitationParams): Promise<void> {
    return this.resource.post(
      {entity: 'invitations', entityId: id, action: 'accept'},
      {user_attributes: params, token},
    );
  }

  async acceptInvitationWithSaml(
    {id, token}: AuthPageParams,
    params: AcceptInvitationParams,
  ): Promise<{redirectTo: string}> {
    return this.makeSamlRequest({
      user_attributes: params,
      token,
      invitation_id: id,
    });
  }

  async signInWithPassword(params: UserSignInParams): Promise<{redirectTo: string}> {
    const redirectTo = this.portalUrl;

    await this.resource.post({action: 'sign_in'}, params);
    this.clearStoredReturnUrl();

    return {redirectTo};
  }

  async signInWithSaml(): Promise<{redirectTo: string}> {
    const result = this.makeSamlRequest();

    this.clearStoredReturnUrl();

    return result;
  }

  async signOut(): Promise<void> {
    await this.resource.post({action: 'sign_out'});

    this.clearStoredReturnUrl();
  }

  async requestPasswordReset(email: User['email']): Promise<void> {
    return this.resource.post({entity: 'password', action: 'reset_token'}, {email});
  }

  async resetPassword({id, token}: AuthPageParams, params: PasswordResetParams): Promise<void> {
    return this.resource.post(
      {entity: 'users', entityId: id, subEntity: 'password', action: 'reset'},
      {...params, token},
    );
  }

  async unlockPassword({id, token}: AuthPageParams): Promise<void> {
    return this.resource.post({entity: 'users', entityId: id, subEntity: 'password', action: 'unlock'}, {token});
  }

  private async loadAuthInfo() {
    let authInfo: AuthInfo;

    try {
      authInfo = await this.resource.get({entity: 'info'});
    } finally {
      runInAction(() => {
        Object.assign(this, authInfo);

        if (authInfo?.user) {
          setUser({
            id: authInfo.user.id,
            ip_address: '{{auto}}',
          });
        }
      });
    }
  }

  private makeSamlRequest(body: SamlInvitationParams | null = null): Promise<{redirectTo: string}> {
    return this.makeRedirectedRequest(
      this.http.post<void>('/portal/sso/saml.json', body, {params: {return_to: this.portalUrl}, observe: 'response'}),
    );
  }

  private async makeRedirectedRequest(request: Observable<HttpResponse<void>>): Promise<{redirectTo: string}> {
    let response;

    try {
      response = await lastValueFrom(request);
    } catch (err) {
      throw this.transformError(err);
    }

    return {redirectTo: response.headers.get('Location')!};
  }

  private transformError(response: HttpErrorResponse): RequestError {
    if (response.status !== 0 && response.error?.error) {
      return new RequestError(response.error.error, false);
    }

    return new RequestError(getHttpErrorMessage(response), true, response);
  }

  private clearStoredReturnUrl() {
    window.sessionStorage.removeItem(RETURN_URL_STORAGE_KEY);
  }
}
